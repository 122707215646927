.modal-benefits {
  .modal-content {
    .modal-header {
      padding: 0;
      border: 0;

      .close {
        position: absolute;
        padding: 0.5rem 0.8rem;
        top: 14px;
        right: 19px;
        color: $cprimary;
        font-size: 2.2rem;
        font-weight: 400;
        opacity: 1;
        z-index: 10;
      }
    }

    .modal-body {
      padding-top: 0;
      padding-bottom: 0;

      >.row {
        align-items: center;
      }

      .col-image {
        min-height: 300px;
        margin-left: -1px;
        align-self: stretch !important;
        border-radius: 4px 0 0 4px;
        background-color: $soft-gray;
      }

      .col-text {
        padding: 18px 28px;
        font-size: 0.95rem;
        line-height: 1.35;

        .title {
          margin-bottom: 8px;
          color: $cprimary;
          font-size: 1.40rem;
          font-weight: 700;
          letter-spacing: 3px;
          line-height: 1.10;
          text-align: center;
          text-transform: uppercase;
        }

        ul {
          padding-left: 16px;
          margin-bottom: 0;
          list-style-type: circle;
        }

        .subtitle {
          color: $cprimary;
          font-size: 1.05rem;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .modal-content {
      .modal-body {
        .col-image {
          margin-top: 1px;
          margin-left: 0;
          border-radius: 4px 4px 0 4px;
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    .modal-content {
      .modal-body {
        .col-image {
          min-height: 250px;
        }

        .col-text {
          .title {
            font-size: 1.25rem;
          }

          .subtitle {
            font-size: 0.95rem;
          }
        }
      }
    }
  }
}
