#footer {
  position: relative;
  color: #fff;
  line-height: 1.4;
  z-index: 100;

  .section-1{
    position: relative;
    padding-top: 26px;
    padding-bottom: 26px;
    width: 100%;
    background-color: #595959;
  }
  .section-2{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #383939;
  }

  a{
    color: #fff;
  }

  .p-sm{
    font-size: 0.75rem;
    line-height: 1.25;
  }

  .logo{
    width: 200px;
  }

  .col-footer{
    margin-top: 7px;
    margin-bottom: 7px;
    font-size: 0.8rem;
    font-weight: 300;

    .pl-p{
      padding-left: 78px;
    }

    .icon{
      font-size: 1.55rem;
    }
  }

  .col-location{
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
    font-size: 0.80rem;

    .loc-name{
      font-size: 1.05rem;
      font-weight: 700;
    }

    h6{
      color: #ebb1c9;
      font-size: 0.90rem;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 767px){
    .col-footer{
      text-align: center;

      .pl-p{
        padding-left: 0 !important;
      }
    }
  }
  @media screen and (max-width: 991px){
    .col-logo{
      text-align: center;
    }
  }
  @media screen and (max-width: 575px){
    .col-location{
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}
