#header{
	position: relative;
	z-index: 60;

	// == Payments ==
	.header-content{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		box-shadow: 0 0px 4px rgba(0,0,0,0.4);
		z-index: 99;
	}
	// == ==

	// == Header menu ==
	.header-menu {
		.navbar {
			background-color: #ffffff !important;

			.navbar-brand {
				img {
					max-height: 72px;
				}
			}

			.navbar-toggler {
				padding: 0.35rem 0.95rem;
				color: #ffffff;
				font-size: 1.6rem;
				border-radius: 0;
				background-color: $cprimary;
			}

			.navbar-nav {
				.simple-item {
					.nav-link {
						color: $csecondary;
						cursor: pointer;
						font-size: 1.20rem;
						font-weight: 600;

						&:hover {
							color: $cprimary;
						}
					}
				}

				.button-item {
					.nav-link {
						position: relative;
						padding: 0.6rem 1.4rem 0.6rem 1.2rem;
						margin-top: -4px;
						color: #ffffff !important;
						background-color: $cprimary;
						@include transition(150ms);

						.icon {
							margin-left: 7px;
						}

						&:hover {
							background-color: $cprimary2 !important;
						}
					}
				}

				.cart-item, .user-item {
					.nav-link {
						color: $csecondary;
						font-size: 1.30rem;
						font-weight: 500;

						.icon {
							font-size: 1.45rem;
						}

						.label {
							display: none;
						}
					}
				}

				.cart-item {
					.nav-link {
						position: relative;

						>div {
							position: relative;
							display: inline-block;
						}

						.num {
							position: absolute;
							top: -9px;
							right: -11px;
							width: 22px;
							height: 22px;
							color: #ffffff;
							line-height: 1;
							font-size: 0.75rem;
							font-style: normal;
							font-weight: 500;
							border-radius: 50%;
							background: $csecondary;
							@include flex-center-xy();
						}
					}
				}

				.user-item {
					&.dropdown {
						.nav-link {
							&:after {
								display: none;
							}
						}

						.dropdown-menu {
							padding: 0.35rem 0;
							border-radius: 0;
							border: 0;
							background-color: $csecondary;

							li {
								.dropdown-item {
									padding: 0.2rem 1.2rem;
									color: #ffffff;
									font-size: 0.93rem;

									&:hover {
										background-color: $csecondary2;
									}
								}
							}
						}
					}
				}
			}
		}

		@media screen and (min-width: 992px) {
			.navbar {
				.navbar-nav {
					.nav-item {
						position: relative;
					}
					.simple-item {
						margin-left: 1.3rem;
						margin-right: 1.3rem;
					}

					.cart-item, .user-item {
						margin-left: 0.4rem;
						margin-right: 0.4rem;
					}
				}
			}

			@media screen and (max-width: 1199px) {
				.navbar {
					.navbar-brand {
						img {
							max-height: 58px;
						}
					}

					.navbar-nav {
						.simple-item {
							margin-left: 0.5rem;
    					margin-right: 0.5rem;
						}

						.button-item {
							.nav-link {
								padding: 0.45rem 1.3rem 0.45rem 1.1rem;
								margin-top: 0;
							}
						}
					}
				}
			}
		}
		@media screen and (max-width: 991px) {
			.navbar {
				.navbar-brand {
					padding-top: 0.15rem;
					padding-bottom: 0.15rem;

					img {
						max-height: 44px;
					}
				}

				#nav-collapse {
					.navbar-nav {
						.simple-item {
							.nav-link {
								padding: 0.3rem 0;
							}
						}

						.button-item {
							.nav-link {
								margin-top: 4px;
								text-align: center;
							}
						}

						.cart-item, .user-item {
							.nav-link {
								padding: 0.4rem 0;

								.icon {
									margin-right: 2px;
									width: 26px;
									font-size: 1.25rem;
									text-align: center;
								}

								.label {
									display: inline-block;
									font-size: 1.15rem;
								}
							}
						}

						.cart-item {
							.nav-link {
								.num {
									right: inherit;
									left: 12px;
								}
							}
						}
					}
				}
			}
		}
	}
	// == ==

}
