.title-s1 {
  color: $csecondary;
  font-size: 3.7rem;
  font-weight: 800;

  @media screen and (max-width: 991px) {
    font-size: 3.2rem;
  }
  @media screen and (max-width: 575px) {
    font-size: 2.9rem;
    line-height: 1.1;
  }
}
.title-s2 {
  color: $cprimary;
  font-size: 2.6rem;
  font-weight: 800;
  letter-spacing: 3px;
  line-height: 1.0;

  @media screen and (max-width: 991px) {
    font-size: 2.10rem;
  }
  @media screen and (max-width: 575px) {
    font-size: 2.10rem;
  }
}
