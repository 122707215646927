#policy-page {

	.banner-section {
		position: relative;

		img {
			width: 100%;
		}
	}

	.description-section {
		position: relative;
		padding-top: 40px;
		padding-bottom: 40px;
		color: #ffffff;
		background-color: $cprimary;

		&.bg-black {
			background-color: #000000;
		}

		.title-s2 {
			margin-bottom: 5px;
			color: #ffffff;
		}

		.p-lg {
			font-size: 1.20rem;
		  font-weight: 500;
		  line-height: 1.30;
			text-align: justify;
		}

		.p-xl {
			font-size: 1.22rem;
			line-height: 1.45;
		}

		@media screen and (max-width: 991px) {
			.p-lg {
				font-size: 1.05rem;
			}

			.p-xl {
				font-size: 1.00rem;
				line-height: 1.45;
			}
		}
		@media screen and (max-width: 575px) {
			padding-bottom: 25px;

			.p-lg {
				font-size: 1.00rem;
			}

			.p-xl {
				font-size: 0.90rem;
				line-height: 1.45;
			}
		}
	}

	.description-section-s2 {
		position: relative;
		padding-top: 40px;
		padding-bottom: 40px;
		color: #ffffff;
		background-color: $cprimary;

		.row-info {
			align-items: center;
			margin-bottom: 12px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.col-image {
			flex: 0 0 25%;
			max-width: 25%;

			.image {
				display: block;
				border-radius: 50%;
				background-color: $soft-gray;

				img {
					width: 100%;
				}
			}
		}

		.col-text {
			font-size: 1.0rem;
			line-height: 1.35;
  		font-size: 0.95rem;
			text-align: justify;

			.title {
				margin-bottom: 4px;
				font-size: 1.30rem;
				font-weight: 600;
			}
		}

		@media screen and (max-width: 991px) {
			.row-info {
				margin-bottom: 18px;
			}

			.col-image {
				order: 0 !important;
				margin: 0 auto 8px auto;
			}

			.col-text {
				flex: 0 0 100%;
				max-width: 100%;
				order: 1 !important;

				.title {
					text-align: center;
				}
			}
		}
		@media screen and (max-width: 767px) {
			padding-top: 30px;
  		padding-bottom: 30px;

			.col-image {
				flex: 0 0 240px;
				max-width: 240px;
			}
		}
	}

	.benefits-img-section {
		text-align: center;

		img {
			max-width: 100%;
		}
	}

	.benefits-section {
		padding-bottom: 25px;

		.col-title {
			margin-bottom: 18px;
			text-align: center;
		}

		.col-benefit {
			margin-bottom: 18px;

			.box-benefit {
				position: relative;
				display: inline-block;
				width: 100%;
				border: 2px solid $cprimary;
				border-radius: 12px;
				overflow: hidden;
				cursor: pointer;
				@include transition(150ms);

				.image {
					display: block;
					height: 150px;
				}

				.text {
					@include flex-center-xy();
					flex: 0 0 100%;
					max-width: 100%;
					min-height: 72px;
					padding: 10px 16px;
					color: #ffffff;
					text-align: center;
					background-color: $cprimary;
					@include transition(150ms);

					h4 {
						font-size: 1.35rem;
  					font-weight: 300;

						&.txt-reduce {
							font-size: 1.25rem;
						}
					}
				}

				&:hover {
					border-color: $cprimary2;

					.text {
						background-color: $cprimary2;
					}
				}
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		@media screen and (max-width: 575px) {
			.col-benefit {
				margin-bottom: 14px;
			}
		}
	}

}
